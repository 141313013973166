import React, { useEffect, useState } from "react";

import { GratifyTextLogo, WebFacebook, WebInstagram, WebPinInterest, WebTwitter } from "../../../assets/svg";

import useAuth from "../../../hooks/useAuth";

import { TABS } from "../../../constants";

import { ACCOUNT_LOGIN, ACCOUNT_SIGNUP, PROFILE, PROFILE_PRIVACY_POLICY, PROFILE_TNC, WELCOME } from "../../../routes/RoutesConstant";

import { Link } from "react-router-dom";
import { StyledFooter, WebstyledFooter } from "./style";

export default function Footer(props) {
  const [selectedTab, setSelectedTab] = useState('home');

  let pathname = window.location.pathname;
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    fetchSelectedTab(pathname);
  }, [pathname]);

  const fetchSelectedTab = (path) => {
    if (path.includes(ACCOUNT_LOGIN) || path.includes(ACCOUNT_SIGNUP) || path.includes(WELCOME)) {
      return;
    }

    // Match each tab's url with the current path using startsWith for better accuracy
    if (path.startsWith(TABS[1].url)) setSelectedTab(TABS[1].key);
    else if (path.startsWith(TABS[2].url)) setSelectedTab(TABS[2].key);
    else if (path.startsWith(TABS[3].url)) setSelectedTab(TABS[3].key);
    else setSelectedTab(TABS[0].key); // Default to home tab
  };

  // Return null for login, signup, or welcome pages to hide the footer
  if (pathname.includes(ACCOUNT_LOGIN) || pathname.includes(ACCOUNT_SIGNUP) || pathname.includes(WELCOME)) {
    return null;
  }

  return (
    <StyledFooter>
      {props?.isPublic && !isAuthenticated ? (
        LogoTextFooter()
      ) : (
        TABS.map((tab, index) => {
          const { key, Icon, text, url } = tab;

          return (
            <div key={index} className={selectedTab === key ? 'selectedTab' : ''}>
              <a href={url}>
                <div><Icon /></div>
                <div>{text}</div>
              </a>
            </div>
          );
        })
      )}
    </StyledFooter>
  );
}

export function LogoTextFooter(props) {
  return (
    <div style={{ padding: "1rem 0.5rem 1rem  1rem" }} className={"row_flex_center w_fit_content margin_auto borderRadius_6 bg_white logoTextFooter" + " " + props?.className}>
      <span className="font_400 fontSize_10" style={{ marginRight: "-0.3rem" }}>powered by</span>
      <GratifyTextLogo style={{ height: "1.6rem" }} />
    </div>
  );
}

export function WebFooter() {
  const { brandData } = useAuth();
  return (
    <WebstyledFooter className="footer">
      <div className="text">© 2023 Adneto Technology Pvt.ltd. All Rights Reserved.</div>
      <Link to={`/${PROFILE}/f3o/${PROFILE_TNC}`} className="text">Terms of Use</Link>
      <Link to={`/${PROFILE}/f0K/${PROFILE_PRIVACY_POLICY}`} className="text">Privacy Policy</Link>
      <div className="display_flex gap_20">
        <Link to={""} className="socialIcon">
          <WebFacebook />
        </Link>
        <Link to={""} className="socialIcon">
          <WebInstagram />
        </Link>
        <Link to={""} className="socialIcon">
          <WebTwitter />
        </Link>
        <Link to={""} className="socialIcon">
          <WebPinInterest />
        </Link>
      </div>
    </WebstyledFooter>
  )
}