import { createBrowserRouter } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";

import {
  HOMEPAGE, ACCOUNT_LOGIN, ACCOUNT_SIGNUP, REWARDS, FEEDBACK, PROFILE, PROFILE_DETAILS, PROFILE_LOYALTY_POINTS,
  PROFILE_MY_REWARDS, PROFILE_REDEEMED_OFFERS, PROFILE_INVOICE, PROFILE_FEEDBACK, PROFILE_TNC, PROFILE_PRIVACY_POLICY,
  PROFILE_CONTACT_US, PROFILE_CAMPAIGN, REWARD_DETAIL_GIFTCARD_PAGE, REWARD_DETAIL_DISCOUNTOFFER_PAGE,
  REWARD_DETAIL_CASHBACK_PAGE, ORDER_SUMMARY_PAGE, WELCOME
} from "./RoutesConstant";

import Spinner from "../components/Atoms/Spinner";
import Error from "../components/Organisms/Error/index.js";
import AppLayout from "../components/Organisms/AppLayout";

import PrivateRoute from "./PrivateRoutes.js";
import ErrorBoundary from "../hoc/ErrorBoundry.js";

// Lazy load the components
const UserWelcomePage = lazy(() => import("../components/Pages/UserWelcomePage"));
const HomePage = lazy(() => import("../components/Pages/HomePageTab"));
const Login = lazy(() => import("../components/Pages/Login"));
const FeedbackTab = lazy(() => import("../components/Pages/FeedbackTab"));
const ProfileTab = lazy(() => import("../components/Pages/ProfileTab"));
const Profile = lazy(() => import("../components/Pages/ProfileTab/Profile"));
const LoyaltyPoints = lazy(() => import("../components/Pages/ProfileTab/LoyaltyPoints"));
const MyRewards = lazy(() => import("../components/Pages/ProfileTab/MyRewards"));
const RedeemedOffers = lazy(() => import("../components/Pages/ProfileTab/RedeemedOffers"));
const Invoice = lazy(() => import("../components/Pages/ProfileTab/Invoice"));
const Feedback = lazy(() => import("../components/Pages/ProfileTab/Feedback"));
const Campaign = lazy(() => import("../components/Pages/ProfileTab/Campaign"));
const TermsOfUse = lazy(() => import("../components/Pages/ProfileTab/TermsOfUse"));
const PrivacyPolicy = lazy(() => import("../components/Pages/ProfileTab/PrivacyPolicy"));
const ContactUs = lazy(() => import("../components/Pages/ProfileTab/ContactUs"));
const RewardsTab = lazy(() => import("../components/Pages/RewardsTab"));
const RewardDetailCashbackPage = lazy(() => import("../components/Pages/RewardDetailPage/CashbackRewardDetailPage.js"));
const RewardDetailGiftCardPage = lazy(() => import("../components/Pages/RewardDetailPage/GiftCardRewardDetailPage.js"));
const RewardDetailDiscountPage = lazy(() => import("../components/Pages/RewardDetailPage/DiscountRewardDetailPage.js"));
const OrderSummaryPage = lazy(() => import("../components/Pages/OrderSummary"));

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}

const WrapWithErrorBoundary = ({ children, isPublic }) => (
  <ErrorBoundary fallback={<Error />}>
    <Suspense fallback={<Spinner />}>
      <AppLayout isPublic={isPublic}>{children}</AppLayout>
    </Suspense>
  </ErrorBoundary>
);

const mobileProfileRoute = [
  {
    path: PROFILE,
    element: (<WrapWithErrorBoundary><ProfileTab /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_DETAILS,
    element: (<WrapWithErrorBoundary><Profile /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_LOYALTY_POINTS,
    element: (<WrapWithErrorBoundary><LoyaltyPoints /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_MY_REWARDS,
    element: (<WrapWithErrorBoundary><MyRewards /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_REDEEMED_OFFERS,
    element: (<WrapWithErrorBoundary><RedeemedOffers /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_INVOICE,
    element: (<WrapWithErrorBoundary><Invoice /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_FEEDBACK,
    element: (<WrapWithErrorBoundary><Feedback /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_CAMPAIGN,
    element: (<WrapWithErrorBoundary><Campaign /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_TNC,
    element: (<WrapWithErrorBoundary isPublic={true}><TermsOfUse /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_PRIVACY_POLICY,
    element: (<WrapWithErrorBoundary isPublic={true}><PrivacyPolicy /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
  {
    path: PROFILE + "/" + PROFILE_CONTACT_US,
    element: (<WrapWithErrorBoundary isPublic={true}><ContactUs /></WrapWithErrorBoundary>),
    errorElement: <Error />,
  },
];

const webprofileRoutes = [
  {
    path: PROFILE,
    element: (<WrapWithErrorBoundary><ProfileTab /></WrapWithErrorBoundary>),
    errorElement: <Error />,
    children: [
      {
        path: PROFILE_DETAILS,
        element: (<WrapWithErrorBoundary><Profile /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
      {
        path: PROFILE_LOYALTY_POINTS,
        element: (<WrapWithErrorBoundary><LoyaltyPoints /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
      {
        path: PROFILE_MY_REWARDS,
        element: (<WrapWithErrorBoundary><MyRewards /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
      {
        path: PROFILE_REDEEMED_OFFERS,
        element: (<WrapWithErrorBoundary><RedeemedOffers /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
      {
        path: PROFILE_INVOICE,
        element: (<WrapWithErrorBoundary><Invoice /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
      {
        path: PROFILE_FEEDBACK,
        element: (<WrapWithErrorBoundary><Feedback /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
      {
        path: PROFILE_CAMPAIGN,
        element: (<WrapWithErrorBoundary><Campaign /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
      {
        path: `f3o/${PROFILE_TNC}`,
        element: (<WrapWithErrorBoundary isPublic={true}><TermsOfUse /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
      {
        path: `f0K/${PROFILE_PRIVACY_POLICY}`,
        element: (<WrapWithErrorBoundary isPublic={true}><PrivacyPolicy /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
      {
        path: `KNa/${PROFILE_CONTACT_US}`,
        element: (<WrapWithErrorBoundary isPublic={true}><ContactUs /></WrapWithErrorBoundary>),
        errorElement: <Error />,
      },
    ],
  },
]

function AppRouter() {
  const isMobile = useIsMobile();
  return createBrowserRouter([
    {
      path: WELCOME,
      element: (<WrapWithErrorBoundary><UserWelcomePage /></WrapWithErrorBoundary>),
      // errorElement: <Error />,
    },
    {
      path: ACCOUNT_LOGIN,
      element: (<WrapWithErrorBoundary><Login /></WrapWithErrorBoundary>),
      errorElement: <Error />,
    },
    {
      path: ACCOUNT_SIGNUP,
      element: (<WrapWithErrorBoundary><Login /></WrapWithErrorBoundary>),
      errorElement: <Error />,
    },
    {
      path: `/${PROFILE}/${PROFILE_TNC}`,
      element: (<WrapWithErrorBoundary isPublic={true}><TermsOfUse /></WrapWithErrorBoundary>),
      errorElement: <Error />,
    },
    {
      path: `/${PROFILE}/${PROFILE_PRIVACY_POLICY}`,
      element: (<WrapWithErrorBoundary isPublic={true}><PrivacyPolicy /></WrapWithErrorBoundary>),
      errorElement: <Error />,
    },
    {
      path: `/${PROFILE}/${PROFILE_CONTACT_US}`,
      element: (<WrapWithErrorBoundary isPublic={true}><ContactUs /></WrapWithErrorBoundary>),
      errorElement: <Error />,
    },
    {
      path: HOMEPAGE,
      element: <PrivateRoute />,
      children: [
        {
          path: "",
          element: (<WrapWithErrorBoundary><HomePage /></WrapWithErrorBoundary>),
          errorElement: <Error />,
        },
        {
          path: REWARDS,
          element: (<WrapWithErrorBoundary><RewardsTab /></WrapWithErrorBoundary>),
          errorElement: <Error />,
        },
        {
          path: REWARD_DETAIL_GIFTCARD_PAGE,
          element: (<WrapWithErrorBoundary><RewardDetailGiftCardPage /></WrapWithErrorBoundary>),
          errorElement: <Error />,
        },
        {
          path: REWARD_DETAIL_DISCOUNTOFFER_PAGE,
          element: (<WrapWithErrorBoundary><RewardDetailDiscountPage /></WrapWithErrorBoundary>),
          errorElement: <Error />,
        },
        {
          path: REWARD_DETAIL_CASHBACK_PAGE,
          element: (<WrapWithErrorBoundary><RewardDetailCashbackPage /></WrapWithErrorBoundary>),
          errorElement: <Error />,
        },
        {
          path: ORDER_SUMMARY_PAGE,
          element: (<WrapWithErrorBoundary><OrderSummaryPage /></WrapWithErrorBoundary>),
          errorElement: <Error />,
        },
        {
          path: FEEDBACK,
          element: (<WrapWithErrorBoundary><FeedbackTab /></WrapWithErrorBoundary>),
          errorElement: <Error />,
        },
        // Conditionally add mobile or web profile routes
        ...(isMobile ? mobileProfileRoute : webprofileRoutes),
      ],
      errorElement: <Error />,
    },
    {
      path: "*",
      element: <WrapWithErrorBoundary><Error /></WrapWithErrorBoundary>,
      errorElement: <Error />,
    },
  ])
}

export default AppRouter;