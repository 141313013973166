import { RouterProvider } from 'react-router-dom';
import AppRouter from './routes';

import './App.css';
import './global.css';

import useWindowSize from './hooks/useWindowSize';

function App() {
  const { isMobile } = useWindowSize();
  return (
    <div className="App" style={{ backgroundColor: isMobile ? "#ffffff" : "#f8f8f8" }}>
      {/* <AppLayout> */}
      <RouterProvider router={AppRouter()} />
      {/* </AppLayout> */}
    </div>
  );
}

export default App;
