import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [windowFlags, setWindowFlags] = useState({
    isMobile: window.innerWidth <= 768,
    isTabSize: window.innerWidth <= 1024,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowFlags({
        isMobile: window.innerWidth <= 768,
        isTabSize: window.innerWidth <= 1024,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowFlags;
};

export default useWindowSize;
