import styled from 'styled-components';

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 4.4rem;
  border-radius: 2rem 2rem 0 0;
  background: #F3F3F3;
  position: fixed;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
  font-size: 1.2rem;
  // font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8rem;
  // line-height: 2.1rem;
  // box-shadow: 1px 1px 4px 2px #f3f3f3;
  box-shadow: 1px 1px 4px 2px grey;
  padding: 1.4rem 2rem;
  // padding: 2.4rem 3rem;
  z-index: 100;

  a {
    color: black;
    text-decoration: none;
    font-weight: 700;
    text-align: center;

    svg, path {
      height: 2rem;
      width: auto;
    }
  }

  .selectedTab a {
    color: ${props => props.theme.colors.primaryColorPink};
    svg, path {
      fill: ${props => props.theme.colors.primaryColorPink};
      stroke: ${props => props.theme.colors.primaryColorPink};
    }
  }
`;

export const WebstyledFooter = styled.div`
    display: flex;
    padding: 15px 10rem;
    justify-content: space-between;
    align-items: center;
    background: #000;
    color: #fff;
    position: relative;
    bottom: 0;
    width: 100%;
    border-radius: 2rem 2rem 0 0;
    
    .text{
      font-size: 1.6rem;
      font-weight: 500;
      color: #ffffff;
      text-decoration: none;
    }

    .socialIcon {
      display: flex;
      width: 4rem;
      height: 4rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      border-radius: 50%;
      background: #464646;
    }
`