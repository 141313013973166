import styled from "styled-components";

export const StyledHeader = styled.div`
  position: relative;
  margin-bottom: 3.6rem;
  // margin-bottom: 6.6rem;

  &.welcomeHeader, &.profileHeader {
    .bgImage {
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }

    .defaultBgImg {
      height: 12rem;
    }

    .backArrow {
      position: absolute;
      top: 1rem;
      left: 0.8rem;

      path {
        fill: white;
      }
    }
  }

  &.welcomeHeader {
    .logo {
      position: absolute;
      bottom: -3.4rem;
      transform: translate(-50%, 0px);
    }

    .thankYouheaderLogo{
      left: 50% !important;
      bottom: -4rem !important;
    }

    .brandLogo {
      width: 8rem;
      height: 8rem;
      // width: 12.5rem;
      // height: 12.5rem;
      // border-radius: 50%;
      // background-color: antiquewhite;

      img {
        width: inherit;
        height: inherit;
        border-radius: 50%;
        object-fit: contain;
        box-shadow: 0 0.5px 1px black;
      }
    }
  }

  &.profileHeader {
    margin-bottom: 6rem;

    .bgImage {
      height: 12rem;
      width: 100%;
    }

    .defaultIcon {
      position: absolute;
      // bottom: -7.8rem;
      // bottom: -5.5rem;
      // bottom: -3.5rem;
      bottom: -3.2rem;
      left: 5.6rem;
      // left: 7.6rem;
      // left: 50%;
      transform: translate(-50%, 0px);
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      background-color: #f1f1f1;
      display: flex;
      align-items: end;
      justify-content: center;
    }

    .logo {
      width: 70px;
      // width: 100px;
      // width: 150px;
      height: auto;
      fill: lightgrey;
      margin-bottom: -4px;
      // margin-bottom: -6px;
      // margin-bottom: -10px;

      path {
        stroke: lightgrey;
      }
    }
  }

  &.profileSubSectionsHeader {
    background: #F6F6F6;
    border-radius: 0 0 3rem 3rem;
    padding: 1.4rem 2rem;
    margin: 0;

    .logo {
      width: 38px;
      height: auto;
    }

    .textLogo {
      height: 34px;
      width: auto;
    }
  }
`;


export const WebstyledHeader = styled.div`
    display: flex;
    padding: 15px 10rem;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: #fff;

    .text{
      font-size: 1.6rem;
      font-weight: 500;
      text-decoration: none;
      color: #000;
    }

    .profile-section{
      position: relative;
    }

`
export const Popup = styled.div`
position: absolute;
top: 60px;
right: 0;
width: 300px;
background-color: #fff;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
border-radius: 10px;
overflow: hidden;
border: 1px solid #bdbdbd;
z-index: 10000;

.popup-header {
  display: flex;
  align-items: center;
  padding: 1.6rem;
  background-color: #f3f3f3;
  border-bottom: 1px solid #ddd;
  gap: 1.6rem;

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .user-details {
    p {
      margin: 0;
      font-size: 1.6rem;
      color: #333;

      &.user-contact {
        font-weight: bold;
        color: #f5437e;
        margin-top: 0.5rem;
      }
    }
  }
}

.popup-options {
  list-style: none;
  margin: 0;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  li {
    font-weight: 600;

    a {
      text-decoration: none;
      color: #333;
      font-size: 14px;

      &:hover {
        color: #f5437e;
      }
    }
  }

  .loyality{
    display: flex;
    justify-content: space-between;
    align-items: center;
    }
    
    .loyality .points {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
    }

    .points .loyalityIcon{
      width: 2rem;
      height: 2rem;
    }
}
`;