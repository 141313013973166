import React from "react";

import Footer, { LogoTextFooter, WebFooter } from "../Footer";
import { ProfileTabHeader, ProfileTabSubSectionsHeader, WebHeader, WelcomeHeader } from "../Header";

import AlertPopup from "../../Molecules/AlertPopup";

import useWindowSize from "../../../hooks/useWindowSize";
import { ACCOUNT_LOGIN, ACCOUNT_SIGNUP, PROFILE_DETAILS, REWARDS, WELCOME } from "../../../routes/RoutesConstant";

export default function AppLayout(props) {
  const { children, isPublic } = props;
  let pathname = window.location.pathname;
  const { isMobile } = useWindowSize();

  function renderHeader() {
    if (pathname.includes(PROFILE_DETAILS))
      return <ProfileTabHeader isProfileTab={false} onBack={true} />
    else if (pathname.includes('/profile/'))
      return <ProfileTabSubSectionsHeader onBack={true} />
    else if (pathname.includes('/profile'))
      return <ProfileTabHeader />
    else if (pathname.includes(REWARDS))
      return null;
    else return <WelcomeHeader />
  }

  const isLoginPage = pathname.includes(ACCOUNT_LOGIN) || pathname.includes(ACCOUNT_SIGNUP);
  const isWelcomePage = pathname.includes(WELCOME);

  return (
    <div style={{ paddingBottom: isWelcomePage ? '0' : (isLoginPage ? isMobile ? '4rem' : "0" : isMobile ? '8rem' : "0") }}>
      <AlertPopup />

      {!isMobile && !isWelcomePage && !isLoginPage && WebHeader()}

      <div>{children}</div>

      {isWelcomePage ? null : (isLoginPage ?
        isMobile ?
          <div style={{ position: "fixed", bottom: "2.4rem", left: "50%", transform: "translate(-50%, 0)" }}>{LogoTextFooter()}</div> : ""
        : isMobile ? <Footer isPublic={isPublic} /> : <WebFooter />)}
    </div>
  );
}
