import React, { useEffect, useRef, useState } from "react";

import {
  BackArrowIcon, GratifyLogoIcon, GratifyTextLogo, LoyaltyPointsIcon, ProfileColorIcon, ProfileTabIcon,
  RightArrow
} from "../../../assets/svg";

import { Link, useNavigate } from "react-router-dom";
import { gratifyWebLogo, myRewardHeader, profileHeader, welcomeHeader } from "../../../assets/png";
import useAuth from "../../../hooks/useAuth";
import useWindowSize from "../../../hooks/useWindowSize";
import { ACCOUNT_LOGIN, FEEDBACK, HOMEPAGE, PROFILE, PROFILE_CONTACT_US, PROFILE_DETAILS, PROFILE_FEEDBACK, PROFILE_INVOICE, PROFILE_LOYALTY_POINTS, PROFILE_MY_REWARDS, REWARDS } from "../../../routes/RoutesConstant";
import { Popup, StyledHeader, WebstyledHeader } from "./style";

export function WelcomeHeader({ bg_image, backgroundHexCode, logo: Logo, onBack = null, isLogoReactElement, thankYouFeedbackPage }) {

  const { isMobile } = useWindowSize();

  return (
    <StyledHeader className="welcomeHeader">
      <div>
        {bg_image
          ? <img src={bg_image} className="bgImage" alt='background-image' style={{
            width: isMobile ? "100dvw" : "100%",
            height: isMobile ? "12rem" : "30rem"
          }} />
          : <img src={welcomeHeader} alt="welcome banner" className="bgImage defaultBgImg" style={{
            width: isMobile ? "100dvw" : "100%",
            height: isMobile ? "12rem" : "30rem"
          }} />
        }
      </div>

      {onBack && <span><BackArrowIcon className="backArrow cursor_pointer" onClick={onBack} /></span>}

      {Logo
        ? (
          <div style={{ left: isMobile ? "17%" : "6rem" }} className={`brandLogo logo customClass ${thankYouFeedbackPage ? "thankYouheaderLogo" : ""}`}>
            {isLogoReactElement ? <Logo style={{ width: '8rem', height: '8rem', background: `#${backgroundHexCode}` }} /> : <img src={Logo} style={{ background: thankYouFeedbackPage ? "none" : `#${backgroundHexCode}` }} alt='logo' />}
          </div>
        ) : <span><GratifyLogoIcon style={{ width: '8rem', height: '8rem', left: "6rem" }} className="logo" /></span>
      }
    </StyledHeader>
  );
}

export function ProfileTabHeader({ onBack = false, isProfileTab = true }) {
  return (
    <StyledHeader className="profileHeader">
      <div>
        {isProfileTab
          ? <img src={profileHeader} alt="profile" className="bgImage" />
          : <img src={myRewardHeader} alt="profile" className="bgImage" />
        }
      </div>

      {/* {!isProfileTab && <span><BackArrowIcon className="backArrow" onBack={() => navigate(PROFILE)} /></span>} */}
      {onBack && <Link to={`/${PROFILE}`}><BackArrowIcon className="backArrow cursor_pointer" /></Link>}

      <div className="defaultIcon"><ProfileTabIcon className="logo" /></div>
    </StyledHeader>
  );
}

export function ProfileTabSubSectionsHeader({ onBack = true }) {
  return (
    <StyledHeader className="profileSubSectionsHeader display_flex gap_10">
      {onBack && <Link to={`/${PROFILE}`} style={{ marginTop: "-0.3rem" }} className="cursor_pointer">
        <RightArrow style={{ marginTop: "1rem", width: "2rem", height: "2rem", transform: "rotate(180deg)" }} />
      </Link>}
      {/* <div onBack={() => navigate(PROFILE)}><BackArrowIcon /></div> */}

      <div className="display_flex gap_6">
        <GratifyLogoIcon className="logo" />
        <GratifyTextLogo className="textLogo" />
      </div>
    </StyledHeader>
  );
}

export function WebHeader() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useRef(null);
  const navigate = useNavigate();

  const { userData, setUserData, setIsAuthenticated } = useAuth();

  const togglePopup = () => {
    setIsPopupVisible((prev) => !prev);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  }

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.removeItem('isPageReloaded');
    setIsAuthenticated(false);
    setUserData({});
    navigate(ACCOUNT_LOGIN);
    setIsPopupVisible(false);
  };

  return (
    <WebstyledHeader className="webHeader">
      <Link to={HOMEPAGE}>
        <img src={gratifyWebLogo} alt="Gratify" loading="lazy" />
      </Link>
      <div className="align_center gap_20">
        <Link to={HOMEPAGE} className="text">
          Home
        </Link>
        <Link to={REWARDS} className="text">
          Rewards
        </Link>
        <Link to={FEEDBACK} className="text">
          Feedback
        </Link>
        <Link to={`/${PROFILE}/KNa/${PROFILE_CONTACT_US}`} className="text">
          Support
        </Link>
        <div className="profile-section" ref={popupRef}>
          <div onClick={togglePopup} className="profile-icon-button cursor-pointer text">
            <ProfileColorIcon />
          </div>
          {isPopupVisible && (
            <Popup>
              <div className="popup-header">
                <ProfileColorIcon />
                <div className="user-details">
                  {userData?.name &&
                    <p>Hello {userData?.name},</p>
                  }
                  {userData?.mobile_number &&
                    <p className="user-contact">{userData?.mobile_number}</p>
                  }
                </div>
              </div>

              <ul className="popup-options">
                <li onClick={closePopup}>
                  <Link to={`/${PROFILE}/${PROFILE_MY_REWARDS}`}>My Rewards</Link>
                </li>
                <li onClick={closePopup}>
                  <Link to={`/${PROFILE}/${PROFILE_DETAILS}`}>Profile</Link>
                </li>
                <li onClick={closePopup}>
                  <Link to={`/${PROFILE}/${PROFILE_FEEDBACK}`}>Feedback</Link>
                </li>
                <li onClick={closePopup}>
                  <Link to={`/${PROFILE}/${PROFILE_INVOICE}`}>Invoice</Link>
                </li>
                <li onClick={closePopup}>
                  <Link className="loyality" to={`/${PROFILE}/${PROFILE_LOYALTY_POINTS}`}>Loyalty Points
                    <div className="points">
                      {userData?.points &&
                        <>
                          <LoyaltyPointsIcon className="loyalityIcon" /> {userData?.points}
                        </>
                      }
                    </div>
                  </Link>
                </li>
                <li onClick={handleLogout}>
                  <Link to={`/${PROFILE}/${PROFILE_INVOICE}`}>Logout</Link>
                </li>
              </ul>
            </Popup>
          )}
        </div>
      </div>
    </WebstyledHeader>
  );
}
