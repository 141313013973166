export const WELCOME = '/welcome';
export const HOMEPAGE = '/';
export const ACCOUNT_LOGIN = '/login';
export const ACCOUNT_SIGNUP = '/signup';
export const REWARDS = '/rewards';
export const FEEDBACK = '/feedback';

// REWARD DETAIL PAGE
export const REWARD_DETAIL_GIFTCARD_PAGE = "rewards/giftcard/:id/:slugId";
export const REWARD_DETAIL_DISCOUNTOFFER_PAGE = "rewards/discount/:id/:slugId";
export const REWARD_DETAIL_CASHBACK_PAGE = "rewards/cashback/:id/:slugId";

// ORDER SUMMARY PAGE
export const ORDER_SUMMARY_PAGE = "order/:offerType/:id";

export const PROFILE = 'profile';
export const PROFILE_DETAILS = 'details';
export const PROFILE_LOYALTY_POINTS = 'loyalty-points';
export const PROFILE_MY_REWARDS = 'my-rewards';
export const PROFILE_REDEEMED_OFFERS = 'redeemed-offers';
export const PROFILE_INVOICE = 'invoice';
export const PROFILE_FEEDBACK = 'feedback';
export const PROFILE_CAMPAIGN = 'campaign';
export const PROFILE_TNC = 'terms-of-use';
export const PROFILE_PRIVACY_POLICY = 'privacy-policy';
export const PROFILE_CONTACT_US = 'contact-us';